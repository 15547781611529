<template>
  <div class="events-table">
    <b-loading is-full-page :active="loading" can-cancel></b-loading>

    <b-table paginated per-page="8" class="table is-fullwidth" v-if="events.length" :data="events">
      <b-table-column numeric label="ID" width="40" v-slot="props">
        {{ props.index + 1 }}
      </b-table-column>

      <b-table-column sortable field="description" label="Descrição" v-slot="props">
        <div class="description-column">
          {{ props.row.description }}
        </div>
      </b-table-column>

      <b-table-column centered sortable field="updateAt" label="Atualizado em" v-slot="props">
        <div class="updated-column"></div>
        {{ props.row.updatedAt | formatDateTime }}
      </b-table-column>

      <b-table-column centered field="status" label="Ativo" v-slot="props">
        <b-field>
          <b-switch
            false-value="INATIVO"
            true-value="ATIVO"
            :value="props.row.status"
            @input="status => updateAdminEventStatus(props.row.id, status)"
          >
            {{ props.row.status === 'ATIVO' ? 'Sim' : 'Não' }}
          </b-switch>
        </b-field>
      </b-table-column>
    </b-table>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import { formatarDataSegundo as formatDateTime } from '@/utils/format';

export default {
  name: 'EventsTable',
  data() {
    return {
      loading: false,
    };
  },
  filters: {
    formatDateTime,
  },
  computed: {
    ...mapState('adminEvents', ['events']),
  },
  methods: {
    ...mapActions('adminEvents', ['updateEventStatus']),
    async updateAdminEventStatus(eventId, status) {
      this.loading = true;
      try {
        await this.updateEventStatus({ eventId, status });
        this.$alerta('Evento atualizado', 'is-success');
      } catch (error) {
        this.$alerta('Erro ao atualizar evento', 'is-danger');
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@media (min-width: 1024px) {
  .description-column {
    width: 60%;
  }

  .updated-column {
    min-width: 140px;
  }
}
</style>
