<template>
  <form @submit.prevent @keyup.enter="createAdminEvent">
    <b-field grouped class="column-direction-touch">
      <b-field
        expanded
        label="Descrição"
        :type="campoInvalido('description', 'data') ? 'is-danger' : ''"
        :message="campoInvalido('description', 'data') ? 'Insira uma descrição' : ''"
      >
        <b-input
          v-model="data.description"
          maxlength="255"
          placeholder="Insira uma descrição para o evento"
          type="textarea"
        >
        </b-input>
      </b-field>

      <b-field label="Adicionar">
        <b-button
          class="button is-primary is-fullwidth"
          :loading="loading.create"
          @click.prevent.stop="createAdminEvent()"
        >
          Adicionar
        </b-button>
      </b-field>
    </b-field>
  </form>
</template>

<script>
import { mapActions } from 'vuex';
import { required } from 'vuelidate/lib/validators';

import fieldsMixin from '@/mixins/formulario';

export default {
  name: 'EventsForm',
  mixins: [fieldsMixin],
  data() {
    const data = {
      description: '',
      status: '',
    };

    const loading = {
      create: false,
    };

    return {
      data,

      loading,
    };
  },
  validations: {
    data: {
      description: { required },
    },
  },
  methods: {
    ...mapActions('adminEvents', ['createEvent']),
    async createAdminEvent() {
      if (this.$v.data.$invalid) {
        this.$v.data.$touch();
        return;
      }

      this.loading.create = true;
      try {
        await this.createEvent({ description: this.data.description });
        this.$alerta('Evento criado', 'is-success');
        this.data.description = '';
      } catch (error) {
        this.$alerta('Erro ao criar evento', 'is-danger');
      } finally {
        this.loading.create = false;
      }
    },
  },
};
</script>
