<template>
  <div>
    <b-loading is-full-page :active="loading.general" can-cancel></b-loading>

    <AppTitle :expand-title="false" titulo="Eventos" />

    <div class="container is-fluid custom-container">
      <div class="columns">
        <div class="column is-8 is-offset-2">
          <EventsForm />

          <hr />

          <EventsTable />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import AppTitle from '@/components/AppTitulo';

import EventsForm from './EventsForm';
import EventsTable from './EventsTable';

export default {
  name: 'Events',
  components: {
    AppTitle,
    EventsForm,
    EventsTable,
  },
  data() {
    const loading = {
      general: false,
      add: false,
    };

    return {
      loading,
    };
  },
  methods: {
    ...mapActions('adminEvents', ['fetchEvents']),
  },
  async created() {
    this.loading.general = true;
    try {
      await this.fetchEvents();
    } catch (error) {
      this.$alerta('Erro ao buscar eventos', 'is-danger');
    } finally {
      this.loading.general = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.custom-container {
  margin: 3rem auto;
}
</style>
